<template>
  <Navigation title="Transaksi Saya" />
  <div class="container pb-5 mb-3">
    <nav class="nav nav-pills nav-justified pt-2 pb-4">
      <a
        class="nav-link text-primary active"
        id="nav-home-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-order"
        type="button"
        role="tab"
        aria-controls="nav-home"
        aria-selected="true"
        >Transaksi</a
      >
      <a
        class="nav-link text-primary"
        id="nav-order-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-invoice"
        type="button"
        role="tab"
        aria-controls="nav-invoice"
        aria-selected="false"
        v-if="this.$store.getters.getRole !== 'hr'"
        >Invoice</a
      >
      <a
        class="nav-link text-primary"
        id="nav-profile-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-result"
        type="button"
        role="tab"
        aria-controls="nav-profile"
        aria-selected="false"
        >Laporan Tes</a
      >
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade active show"
        id="nav-order"
        role="tabpanel"
        aria-labelledby="nab-order-tab"
      >
        <div
          class="card shadow rounded rounded-3 mb-3"
          v-for="transaction in transactions"
          :key="transaction"
        >
          <div class="card-header p-0" style="background-color: #97cace">
            <p class="text-center fw-bold text-white pt-2 pb-0">
              {{ transaction.date_group }}
            </p>
          </div>
          <div class="card-body">
            <div
              class=""
              v-for="(tester_data, index) in transaction.tester"
              :key="tester_data"
            >
              <div class="d-flex justify-content-between">
                <div class="align-self-center">
                  <table>
                    <tr
                      v-if="
                        tester_data.status_process === 'queued up' &&
                        this.$store.getters.getRole !== 'hr'
                      "
                    >
                      <td rowspan="4">
                        <input
                          type="checkbox"
                          class="me-2"
                          :value="{
                            id: tester_data.id,
                            price: tester_data.total_price,
                          }"
                          v-model="selected_transaction"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="pe-2">
                        <i class="bi bi-person-fill text-primary"></i>
                      </td>
                      <td>
                        <b>{{ tester_data.fullname }}</b> ({{
                          tester_data.email
                        }})
                      </td>
                    </tr>
                    <tr>
                      <td class="pe-2">
                        <i class="bi bi-book-fill text-primary"></i>
                      </td>
                      <td>{{ tester_data.package_name }}</td>
                    </tr>
                    <tr v-if="this.$store.getters.getRole === 'hr'">
                      <td class="pe-2">
                        <i
                          class="bi bi-exclamation-triangle-fill text-danger"
                        ></i>
                      </td>
                      <td>
                        <b class="text-primary">Batas Waktu tes:</b> sampai
                        {{ tester_data.due_date }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  class="align-self-center"
                  v-if="
                    tester_data.status_process === 'draft' ||
                    tester_data.status_process === 'retake'
                  "
                >
                  <button
                    class="btn btn-info shadow text-white rounded"
                    style="font-size: 8pt"
                    @click="toggle_method_modal(tester_data)"
                  >
                    Tes Sekarang
                  </button>
                </div>
                <div
                  class="align-self-center text-success text-center"
                  style="font-size: 11pt"
                  v-else-if="tester_data.status_process === 'queued up'"
                >
                  Belum pembayaran
                </div>
                <div
                  class="align-self-center text-success text-center"
                  style="font-size: 11pt"
                  v-else
                >
                  Sudah tes
                </div>
              </div>
              <hr v-if="index !== Object.keys(transaction.tester).length - 1" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="nav-invoice"
        role="tabpanel"
        aria-labelledby="nav-invoice-tab"
        v-if="this.$store.getters.getRole !== 'hr'"
      >
        <div
          class="card shadow rounded rounded-3 mb-3"
          v-for="invoice in invoices"
          :key="invoice"
        >
          <div class="card-header p-0" style="background-color: #97cace">
            <div class="d-flex justify-content-between align-items-center px-3">
              <p class="text-center fw-bold text-white pt-2 pb-0">
                Status: {{ invoice.status_invoice }}
              </p>
              <p class="text-center fw-bold text-white pt-2 pb-0">
                {{ invoice.date_created }}
              </p>
            </div>
          </div>
          <div class="card-body">
            <div
              class=""
              v-for="(tester_data, index) in invoice.transactions"
              :key="tester_data"
            >
              <div class="d-flex justify-content-between">
                <div class="align-self-center">
                  <table>
                    <tr>
                      <td class="pe-2">
                        <i class="bi bi-person-fill text-primary"></i>
                      </td>
                      <td>
                        <b>{{ tester_data.fullname }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td class="pe-2">
                        <i class="bi bi-book-fill text-primary"></i>
                      </td>
                      <td>{{ tester_data.package_name }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <hr
                v-if="index !== Object.keys(invoice.transactions).length - 1"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="nav-result"
        role="tabpanel"
        aria-labelledby="nab-result-tab"
      >
        <p
          class="text-center mt-3 text-muted"
          style="font-size: 9pt"
          v-if="this.$store.getters.getRole !== 'hr'"
        >
          Laporan hasil tes akan dikirimkan melalui email
        </p>
        <p class="text-center mt-3 text-muted" style="font-size: 9pt" v-else>
          Untuk laporan hasil tes silahkan menghubungi <br />
          institusi terkait yang menyelenggarakan proses assessmen
        </p>
      </div>
    </div>
  </div>
  <div
    class="position-fixed"
    style="right: 5%; bottom: 10%"
    v-if="selected_transaction.length > 0"
  >
    <button
      class="btn btn-primary text-center"
      style="width: 75px; height: 75px; border-radius: 50px"
      @click="confirm_payment"
    >
      <i class="bi bi-cart-check fs-1"></i>
    </button>
  </div>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Metode pengambilan</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="lead">(Pilih salah satu)</p>
          <div class="d-flex align-items-center gap-2 mb-3">
            <input
              type="radio"
              name="taking_method"
              @click="this.taking_method = 'jari'"
              id="taking_method_1"
            />
            <label for="taking_method_1">
              <p class="fw-bold m-0">Foto Jari</p>
              <p class="m-0">
                Foto jari Anda secara langsung menggunakan kamera.
              </p>
            </label>
          </div>
          <div class="d-flex align-items-center gap-2 mb-2">
            <input
              type="radio"
              name="taking_method"
              @click="this.taking_method = 'cap'"
              id="taking_method_2"
            />
            <label for="taking_method_2">
              <p class="fw-bold m-0">
                Foto Cap Jari <span class="badge bg-primary">Beta</span>
              </p>
              <p class="m-0">
                Gunakan cap jari Anda sebagai pengganti foto jari.
              </p>
            </label>
          </div>

          <button
            class="btn btn-primary mt-3"
            v-if="this.taking_method"
            @click="select_method()"
          >
            Lanjutkan
          </button>
        </div>
      </div>
    </div>
  </div>
  <BottomNav />
</template>

<script>
import BottomNav from "../components/BottomNav";
import Navigation from "../components/Navigation";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";

export default {
  name: "Transactions",
  components: { Navigation, BottomNav },
  data() {
    return {
      transactions: [],
      invoices: [],
      reports: [],
      selected_transaction: [],
      taking_method: "",
      method_modal: null,
    };
  },
  created() {
    let self = this;
    let is_hr = this.$store.getters.getRole === "hr" ? "?is_hr=yes" : "";
    axios
      .get("/api/transaction" + is_hr, { withCredentials: true })
      .then((response) => {
        self.transactions = response.data;
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch("setAuth", false);
        this.$router.push("/");
      });
    if (this.$store.getters.getRole !== "hr") {
      axios
        .get("/api/invoice", { withCredentials: true })
        .then((response) => {
          self.invoices = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setAuth", false);
          this.$router.push("/");
        });
      axios
        .get("/api/report", { withCredentials: true })
        .then((response) => {
          self.reports = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setAuth", false);
          this.$router.push("/");
        });
    }
  },
  methods: {
    async confirm_payment() {
      let self = this;
      Swal.fire({
        title: "Lanjutkan ke pembayaran ?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        denyButtonText: "Tidak",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let trx_id = [];
          let price_sum = 0;
          let rawFormData = this.selected_transaction;
          let formData = {};
          for (let i = 0; i < rawFormData.length; i++) {
            trx_id.push(rawFormData[i].id);
            price_sum += parseFloat(rawFormData[i].price);
          }

          axios
            .post("/api/create_invoice", {
              user_id: this.$store.getters.getUser.id,
              transaction_id: trx_id.join(),
            })
            .then((response) => {
              formData.payment_id = response.data.id;
              formData.trx_id = trx_id.join();
              formData.price_sum = price_sum;
              self.$store.dispatch("setCheckout", formData);
              self.$router.push("/payment");
            })
            .catch((error) => {
              Swal.fire("Gagal", "Terjadi kesalahan saat checkout", "error");
              console.log(error);
            });
        }
      });
    },

    toggle_method_modal(tester_data) {
      this.method_modal = new Modal(document.getElementById("exampleModal"), {
        keyboard: false,
        backdrop: "static",
      });
      this.method_modal.show();

      this.$store.dispatch("setTransaction", tester_data.id);
    },

    select_method() {
      this.method_modal.hide();

      if (this.taking_method == "jari") {
        this.$router.push({ name: "Capture" });
      } else if (this.taking_method == "cap") {
        this.$router.push({ name: "CaptureStamp" });
      } else {
        alert("Pilih salah satu");
      }
    },
  },
};
</script>

<style scoped>
table {
  font-size: 8pt;
}

.nav-link {
  border-radius: 0 !important;
  background: white !important;
  color: #6c757d !important;
  border-bottom: 3px solid #6c757d !important;
}

.nav-link.active {
  color: #0d6efd !important;
  border-bottom: 3px solid #0d6efd !important;
}
</style>
