import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";

createApp(App).use(store).use(router).mount("#app");
//import { createApp } from "vue";
//import Vue from "vue";
//import App from "./App.vue";
//import router from "./router";
//import store from "./store";
//import "bootstrap";
//import VueI18n from "vue-i18n";
//import { VueTolgee } from '@tolgee/vue';
//import { tolgee } from './lib/tolgee';

//Vue.use(VueI18n);

//let app = createApp(App).use(VueI18n);

/*const i18n = new VueI18n({
  // set locale
  locale: "en",
  // set locale messages
  messages: {
    en: {
      message: {
        hello: "hello world",
      },
    },
    ja: {
      message: {
        hello: "こんにちは、世界",
      },
    },
  },
});/*

//Vue.config.productionTip = false;

/*new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");*/
//createApp(App).use(VueI18n).use({ i18n }).use(store).use(router).mount("#app");
//createApp(App).use(store).use(router).use(VueI18n).mount("#app");
//app.use({ i18n }).use(store).use(router).mount("#app");
//createApp(App).use(store).use(router).use(VueTolgee, { tolgee }).mount('#app')
