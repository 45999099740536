<template>
  <div class="container">
    <!-- Tips Modal -->
    <div
      class="modal fade bg-info"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div
          class="modal-content"
          style="
            background-color: transparent !important;
            border: 0 solid transparent !important;
          "
        >
          <h4 class="text-center text-white mt-3 mb-5">Foto Cap Sidik Jari</h4>
          <div
            class="modal-body rounded rounded-bottom mb-5"
            style="background-color: white"
          >
            <h4 class="text-center fw-bold mb-4">
              Petunjuk Foto Cap Sidik Jari
            </h4>
            <div v-for="(tip, index) in tips" :key="index">
              <div class="d-flex mb-2" v-if="tip.show">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="`tips${index}`"
                    @click="toggleTutorial(index)"
                    :disabled="tip.checked"
                  />
                  <label
                    class="form-check-label"
                    :for="`tips${index}`"
                    v-html="tip.data"
                  ></label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <div class="d-grid col-6 mx-auto" v-if="isTipsFinish">
              <button
                type="button"
                class="btn"
                @click="this.isTimerEnabled = true"
                style="background-color: yellow"
                data-bs-dismiss="modal"
              >
                Lanjutkan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Upload Form -->
    <div class="container mt-4">
      <h3>Upload Finger Images</h3>
      <div class="row">
        <!-- Tangan Kiri -->
        <div class="col-md-6">
          <h4>Tangan Kiri</h4>
          <!-- Loop untuk jari tangan kiri -->
          <div
            v-for="(finger, index) in leftHandFingers"
            :key="index"
            class="mb-3"
          >
            <label :for="'finger-left-' + index" class="form-label">
              Upload Gambar {{ finger }}
            </label>
            <input
              type="file"
              class="form-control"
              :id="'finger-left-' + index"
              accept="image/*"
              @change="handleFileChange(index, $event, 'left')"
            />
            <!-- Preview gambar yang diupload -->
            <div v-if="images.left[index]" class="mt-2">
              <h6>Preview:</h6>
              <img
                :src="images.left[index].preview"
                alt="Preview"
                class="img-thumbnail"
                style="max-width: 150px; max-height: 150px"
              />
            </div>
            <!-- Status upload -->
            <div v-if="uploadStatuses.left[index]" class="mt-1">
              <span
                class="badge"
                :class="{
                  'bg-success': uploadStatuses.left[index] === 'success',
                  'bg-danger': uploadStatuses.left[index] === 'error',
                  'bg-warning': uploadStatuses.left[index] === 'loading',
                }"
              >
                {{ uploadStatuses.left[index] }}
              </span>
            </div>
          </div>
        </div>

        <!-- Tangan Kanan -->
        <div class="col-md-6">
          <h4>Tangan Kanan</h4>
          <!-- Loop untuk jari tangan kanan -->
          <div
            v-for="(finger, index) in rightHandFingers"
            :key="index"
            class="mb-3"
          >
            <label :for="'finger-right-' + index" class="form-label">
              Upload Gambar {{ finger }}
            </label>
            <input
              type="file"
              class="form-control"
              :id="'finger-right-' + index"
              accept="image/*"
              @change="handleFileChange(index, $event, 'right')"
            />
            <!-- Preview gambar yang diupload -->
            <div v-if="images.right[index]" class="mt-2">
              <h6>Preview:</h6>
              <img
                :src="images.right[index].preview"
                alt="Preview"
                class="img-thumbnail"
                style="max-width: 150px; max-height: 150px"
              />
            </div>
            <!-- Status upload -->
            <div v-if="uploadStatuses.right[index]" class="mt-1">
              <span
                class="badge"
                :class="{
                  'bg-success': uploadStatuses.right[index] === 'success',
                  'bg-danger': uploadStatuses.right[index] === 'error',
                  'bg-warning': uploadStatuses.right[index] === 'loading',
                }"
              >
                {{ uploadStatuses.right[index] }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<button
      @click="sendPicture"
      class="btn btn-primary mt-3"
      :disabled="isLoading"
    >
      Kirim Semua Gambar
    </button>-->
  </div>
</template>

<script>
export default {
  /*name: "Capture",
  components: { BottomNav, Navigation },
  mounted() {
    document.getElementById("btn_modal").click();
    this.isCameraOpen = true;
    this.isTutorialWatched = false;
    this.createCameraElement();
    this.transaction_id = this.$store.getters.getTransaction;
    this.checkFinger();
    this.getTransactionData();
    // this.tips.forEach((tip, idx) => {
    //     this.delayedList(tip, idx+1)
    // })
  },*/
  data() {
    return {
      // Status upload per jari dan per indeks
      uploadStatuses: {
        left: [],
        right: [],
      },
      images: {
        left: [],
        right: [],
      },
      isLoading: false,
      isTipsFinish: false, // Track if all tips are checked
      transaction_id: "", // Transaction ID
      tips: [
        {
          data: "Pastikan gambar jari tampak jelas dan terang.",
          show: true,
          checked: false,
        },
        {
          data: "Posisikan jari dengan benar agar cap sidik jari terbaca dengan jelas.",
          show: true,
          checked: false,
        },
        {
          data: "Jangan terlalu dekat atau terlalu jauh dari kamera.",
          show: true,
          checked: false,
        },
        {
          data: "Pastikan semua jari diupload dengan urutan yang benar.",
          show: true,
          checked: false,
        },
      ],
      fingers: [
        {
          name: "Tangan Kiri",
          fingers_name: [
            "left_thumb",
            "left_forefinger",
            "left_middlefinger",
            "left_thirdfinger",
            "left_littlefinger",
          ],
        },
        {
          name: "Tangan Kanan",
          fingers_name: [
            "right_thumb",
            "right_forefinger",
            "right_middlefinger",
            "right_thirdfinger",
            "right_littlefinger",
          ],
        },
      ],
      fingers_done: {
        left_thumb: false,
        left_forefinger: false,
        left_middlefinger: false,
        left_thirdfinger: false,
        left_littlefinger: false,
        right_thumb: false,
        right_forefinger: false,
        right_middlefinger: false,
        right_thirdfinger: false,
        right_littlefinger: false,
      },
      finger_images: {
        left_thumb: null,
        left_forefinger: null,
        left_middlefinger: null,
        left_thirdfinger: null,
        left_littlefinger: null,
        right_thumb: null,
        right_forefinger: null,
        right_middlefinger: null,
        right_thirdfinger: null,
        right_littlefinger: null,
      },
    };
  },
  computed: {
    // Jari tangan kiri
    leftHandFingers() {
      return this.fingers[0].fingers_name;
    },
    // Jari tangan kanan
    rightHandFingers() {
      return this.fingers[1].fingers_name;
    },
  },
  methods: {
    // Menangani perubahan file upload
    handleFileChange(index, event, hand) {
      const file = event.target.files[0];
      if (file) {
        // Menyiapkan preview gambar sebelum di-upload
        const reader = new FileReader();
        reader.onload = () => {
          // Memastikan array untuk hand (left atau right) ada
          if (!this.images[hand]) {
            this.images[hand] = [];
          }

          // Menyimpan preview gambar dalam data Vue
          this.images[hand][index] = {
            file: file,
            preview: reader.result, // Ini adalah gambar dalam format base64
          };

          // Menandai status upload menjadi "loading"
          if (!this.uploadStatuses[hand]) {
            this.uploadStatuses[hand] = [];
          }
          this.uploadStatuses[hand][index] = "loading";

          // Memanggil fungsi untuk mengupload gambar setelah pemilihan file
          this.sendPicture(index, hand);
        };
        reader.readAsDataURL(file); // Membaca file sebagai URL Base64 untuk preview gambar
      }
    },
    async sendPicture(index, hand) {
      // Cek apakah gambar tersedia
      const image = this.images[hand][index];
      if (!image) return;

      const base64Image = image.preview;

      const fingerName =
        hand === "left"
          ? this.fingers[0].fingers_name[index]
          : this.fingers[1].fingers_name[index];

      let trans_id = this.transaction_id;
      // FormData untuk dikirim ke server
      const form = new FormData();
      form.append("id", trans_id);
      form.append("fingername", fingerName);
      form.append("image", base64Image);

      // Update status menjadi loading
      this.uploadStatuses[hand][index] = "loading";

      try {
        // Kirim gambar ke server menggunakan axios
        const response = await axios.post("/api/update_finger_stamp", form);

        // Jika status sukses, set status upload ke success
        const status = response.data.status ? "success" : "error";
        this.uploadStatuses[hand][index] = status;

        if (response.data.status) {
          // Update fingers_done dan finger_images jika upload sukses
          this.fingers_done[fingerName] = true;
          this.finger_images[fingerName] = image.preview;
        }
      } catch (error) {
        // Jika terjadi error saat upload
        console.error(`Error uploading file ${hand}-${index}:`, error);
        this.uploadStatuses[hand][index] = "error";

        // Menampilkan notifikasi kesalahan
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Terjadi kesalahan saat mengunggah gambar. Silakan coba lagi.",
          showConfirmButton: true,
        });
      }
    },
    async checkFinger() {
      let self = this;
      await axios
        .post("/api/check_finger", { id: this.transaction_id })
        .then(function (response) {
          let response_data = response.data;
          Object.keys(response_data).forEach(function (key) {
            if (response_data[key] !== null) {
              self.fingers_done[key] = true;
            }
          });
          // console.log(response_data['right_forefinger'])
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async getTransactionData() {
      let self = this;
      await axios
        .post("/api/check_transaction", { id: this.transaction_id })
        .then(function (response) {
          self.transaction_data = response.data;
        })
        .catch((error) => console.log(error));
    },
    async startCountdown() {
      this.isTimerEnabled = true;
    },
    delayedList(tips, idx) {
      let delay = 1500 * idx;
      setTimeout(() => {
        tips.show = true;
      }, delay);
    },
    toggleTutorial(id) {
      this.tips[id].checked = true;

      if (this.tips.length - 1 == id) {
        this.isTipsFinish = true;
      } else {
        this.tips[id + 1].show = true;
      }
    },
  },
  watch: {
    isTipsFinish(newVal) {
      if (newVal) {
        // Automatically trigger the modal after all tips are checked
        /*setTimeout(() => {
          document.getElementById("btn_modal").click();
        }, 500);*/
      }
    },
  },
  mounted() {
    // Automatically trigger the modal to show the tips at first
    /*setTimeout(() => {
      document.getElementById("btn_modal").click();
    }, 500);*/
  },
};
</script>

<style scoped>
/* Menambahkan sedikit styling jika diperlukan */
.img-thumbnail {
  max-width: 150px;
  max-height: 150px;
}
.preview-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
}

.finger-group {
  margin-bottom: 20px;
}

.finger {
  margin-bottom: 10px;
}
</style>
